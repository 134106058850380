<template>
    <div>
        <div class="tab-bar">
            <div
                v-for="item in routeViews"
                :key="item.routerName"
                @click="changeTabBar(item.routeName[0])"
                :class="{ active: item.routeName.includes($route.name) }"
            >
                <span>
                    {{ item.name }}
                    <span class="dot" v-if="item.showDot"></span>
                </span>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: "enterpriseService",
    data() {
        return {
            routeViews: [
                {
                    name: "订单",
                    routeName: [
                        "enterpriseServiceInquiry",
                        "enterpriseServiceHasSigning",
                        "enterpriseServiceNoSigning",
                        "enterpriseServiceHasRefund",
                    ],
                    showDot: false,
                },
                {
                    name: "项目管理",
                    routeName: ["enterpriseServiceItems"],
                    showDot: false,
                },
            ],
        };
    },
    mounted() {
        this.$vc.on(this.$route.path, "dot", "enterpriseOrder", (isShow) => {
            this.$set(this.routeViews[0], "showDot", isShow);
        });
    },
    methods: {
        changeTabBar(routerName) {
            this.$router.push({ name: routerName });
        },
    },
};
</script>

<style scoped></style>
